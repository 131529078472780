/* imported from ../../../../../meepshop/utils/styles/variables.less  */

/** ignore meepshop id */
@import '../../../../../meepshop/utils/styles/~antd/lib/style/themes/default.less';
@text-color: rgba(0, 0, 0, 0.65);
@border-radius: 4px;
@border-radius-base: 4px;
@border-color-split: #e8e8e8;

@btn-text-shadow: none;
@btn-shadow: none;
@btn-primary-shadow: none;

@checkbox-check-bg: transparent;

/* imported from widget.less  */

#meepshop {

.meepshop-meep-ui__block-widget__root {
  width: 100%;
  justify-content: center;
}

.meepshop-meep-ui__block-widget__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
 }
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";