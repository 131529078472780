/* imported from styles.less  */

/** ignore meepshop id */
@import '~antd/dist/antd.less';

@import '../../styles/variables.less';

* {
  /** make scrolling smoothy in safari, ios */
  -webkit-overflow-scrolling: touch;
  /* Because the default value of IE is diifferent with other browser */
  flex-shrink: 1;
}

html,
body,
#__next {
  height: 100%;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  text-shadow: none;
}

.ant-cascader-picker-arrow {
  transition: transform 0.2s;

  &.ant-cascader-picker-arrow-expand {
    transform: rotate(180deg);
  }
}

.ant-select-arrow {
  transition: transform 0.2s;
}

.ant-select.ant-select-open
  .ant-select-arrow
  .anticon:not(.anticon-search) {
  transform: rotate(180deg);
}

.ant-table {
  background-color: transparent;
}

.ant-input,
.ant-select-selection-search-input,
.ant-select-selection-item,
.ant-select-selection-placeholder {
  font-size: 16px;
}
