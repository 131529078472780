/*  imported from storeDesign.less */

#meepshop { /* TODO: add global className*/
button > span {
  color: inherit;
}

pre {
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  word-break: break-word;
  white-space: pre-wrap;
}

.ant-badge-count {
  font-size: 10px;
}

.ant-badge {
  color: inherit;
}

.meepshop.ant-menu-vertical,
.meepshop.ant-menu-inline {
  &.ant-menu,
  &.ant-menu .ant-menu-item-selected,
  .ant-menu-item:hover,
  .ant-menu-item:after,
  .ant-menu-item-active,
  .ant-menu-item.ant-menu-item-selected:after,
  .ant-menu-submenu > .ant-menu,
  .ant-menu-submenu:hover,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover,
  .ant-menu-submenu-title:active,
  .ant-menu-submenu-inline > .ant-menu-submenu-title:hover,
  .ant-menu-submenu-open {
    color: inherit;
    background: transparent;
    border: 0px;
  }
}

.ant-menu-submenu-title {
  transition: unset;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu {
  top: 0px;
  margin: 0px;
  border: 0px;
}

.ant-menu-vertical .ant-menu-item:not(:last-child) {
  margin: 0px;
}

.ant-table-thead > tr > th {
  background: transparent;
}
 }
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";