/*  imported from index.less  */

@import '~@meepshop/utils/styles/variables.less';
#meepshop {
.meepshop-action-button__index__root {
  position: fixed;
  right: 24px;
  bottom: 40px;

  @media (max-width: @screen-sm-max) {
    right: 16px;
    bottom: 60px;
  }
}
}
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";