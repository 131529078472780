/*  imported from backToTop.less  */

#meepshop { .meepshop-action-button__backToTop__root {
  position: initial;
  width: auto;
  height: auto;

  > div {
    height: 100%;
  }

  &:empty {
    display: block;
  }

  .anticon-up {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: 0.4s all ease-out;
    width: 40px;

    &.ant-fade-leave-active {
      height: 0;
      margin-top: 0;

      svg {
        width: 0;
        height: 0;
      }
    }

    &.ant-fade-enter-active {
      height: 40px;
      margin-top: 20px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
 }
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";