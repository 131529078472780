/*  imported from goToButton.less  */

#meepshop { .meepshop-action-button__goToButton__root {
  cursor: pointer;
  border-radius: 50%;
  padding: 8px;
  width: 40px;
  height: 40px;

  .meepshop-action-button__goToButton__icon {
    width: 24px;
    height: 24px;
    fill-rule: evenodd;
  }
}
 }
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";