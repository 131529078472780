/*  imported from handleModuleData.less */

@import '../../constants/styles.less';
#meepshop {
@media @PHONE_MEDIA {
  .meepshop-meep-ui__menu-handleModuleData__root {
    flex-wrap: initial !important;
    justify-content: flex-start !important;
    overflow-x: scroll;

    /* 因為調整了 packages/meep-ui/src/link/styles/index.less 的 span.link， 仍須保持 overflow-x: scroll 底下的 link.span，讓他們呈現原本的文字寬度*/
    li span {
      white-space: inherit;
    }

    .ant-menu.ant-menu-vertical {
      flex-wrap: initial;
    }
  }
}
}
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";