/* imported from styles.less  */

/** ignore meepshop id */
@import '~@meepshop/utils/lib/styles/styles.less';

@import '../../styles/variables.less';

body.ant-scrolling-effect {
  /* 這邊是為了解決 ANTD 在 Modal, Drawer 這種全螢幕型的 Component 打開時會將 body 的 width 變成 calc(100% - 15px)*/
  width: 100% !important;
}

/* Overriding Antd ::selection https://github.com/ant-design/ant-design/issues/26587*/
/* 不讓圖片長點擊時會反藍*/
img::-moz-selection {
  background: inherit !important;
  color: inherit !important;
}

img::selection {
  color: inherit !important;
  background: inherit !important;
}

.ant-cascader-menus {
  & > div {
    display: flex;
  }

  .ant-cascader-menu-item {
    display: flex;
    align-items: center;
    white-space: pre-line;
    word-break: break-word;
  }
}

.ant-form-item {
  margin: 0px 0px 14px;
}

.ant-tabs-nav {
  .ant-tabs-tab {
    &:hover {
      color: #00c5dc;
    }
  }

  .ant-tabs-tab-active {
    color: #00c5dc;
  }
}

.ant-tabs-ink-bar {
  background-color: #00c5dc;
}

.ant-btn-primary {
  background-color: #00c5dc;
  border-color: #00c5dc;

  &:hover {
    background-color: #00c5dc;
    border-color: #00c5dc;
  }

  &:focus {
    background-color: #00c5dc;
    border-color: #00c5dc;
  }
}

/* fix ios safari rendering problem (T3764)*/
.ant-select-dropdown-menu-item:last-child,
.ant-select-dropdown-menu-item:first-child {
  -webkit-transform: translate3d(0, 0, 0);
}

@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";