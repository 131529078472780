/*  imported from error.less  */

@import '~@meepshop/utils/styles/variables.less';
#meepshop {
.meepshop-page-error__error__root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #e4fafa;

  @media (max-width: @screen-sm-max) {
    flex-direction: column;
  }
}

.meepshop-page-error__error__image {
  position: relative;
  width: 375px;
  height: 250px;

  img {
    position: absolute;

    &:nth-child(1) {
      width: 100%;
    }

    &:nth-child(2) {
      top: 90px;
      left: 290px;
      width: 25px;
      transform-origin: -30px -30px;
      animation: meepshop-page-error__error__rotate 2s ease-out infinite;
    }

    &:nth-child(3) {
      top: 90px;
      left: 100px;
      width: 200px;
      animation: meepshop-page-error__error__move 6s infinite;
    }
  }
}

@keyframes meepshop-page-error__error__move {
  0% {
    transform: rotate(5deg);
    top: 70px;
    left: 100px;
  }
  33% {
    transform: rotate(-10deg);
    top: 80px;
    left: 130px;
  }
  66% {
    transform: rotate(10deg);
    top: 90px;
    left: 60px;
  }
  100% {
    transform: rotate(5deg);
    top: 70px;
    left: 100px;
  }
}

@keyframes meepshop-page-error__error__rotate {
  0% {
    transform: rotate(45deg) scale(1, 1);
    opacity: 1;
  }

  100% {
    transform: rotate(-45deg) scale(1.5, 1.5);
    opacity: 0;
  }
}

.meepshop-page-error__error__message {
  margin: 0px 0px 0px 20px;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 30px;

  @media (max-width: @screen-sm-max) {
    margin: 16px 0px 0px;
    text-align: center;
  }

  h1 {
    margin: 0px 0px 8px;
    font-size: 32px;
    color: #4a4f5c;
    line-height: 50.4px;
    letter-spacing: 2px;

    > span {
      display: block;
    }

    @media (max-width: @screen-sm-max) {
      font-size: 20px;
    }
  }

  p {
    margin: 0px;
    font-size: 18px;
    color: #757d97;
    font-weight: 400;

    @media (max-width: @screen-sm-max) {
      font-size: 14px;
    }
  }
}

.meepshop-page-error__error__errorId {
  margin-top: 32px;

  p {
    font-size: 14px;
    color: #4a4f5c;
    line-height: 19.6px;
  }
}

.meepshop-page-error__error__id {
  display: inline-block;
  padding: 12px 14px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  font-size: 12px;
  font-weight: 700;
  color: #757d97;
  margin-top: 16px;
}
}
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";